<template>
  <section class="page page-settings">
    <div class="wrapper">
      <div class="breacrumbs">
        <router-link to="/">Главная</router-link>
        <span class="separator">/</span>
        <span class="current">Настройки</span>
      </div>
      <div class="page-title">
        <h1>Настройки</h1>
      </div>
      <form class="block-settings" @submit.prevent="submitHandler">
        <div class="main">
          <div v-if="subscription && subscription.active" class="item tarif">
            <label>Ваш тариф</label>
            <div class="form-group">
              <div role="alert">
                <div>Дата активации подписки: {{ subscription.createdOn }}</div>
                <div>Дата окончания подписки: {{ subscription.expiredOn }}</div>
              </div>
            </div>
          </div>

          <div class="item avatar-body">
            <label>Аватарка</label>
            <div class="avatar">
              <div class="img">
                <img :src="avatar_img" alt="avatar" />
              </div>
            </div>
          </div>

          <div class="item">
            <small v-if="!verified" style="color: darkred">
              Почта не подтверждена:
              <button
                class="button button-type-2"
                v-on:click.self="sendVerifyEmail"
                id="verify"
              >
                ПОДТВЕРДИТЬ
              </button>
            </small>
          </div>

          <div class="item">
            <label>Логин</label>
            <div class="form-group">
              <input
                v-model.trim="v$.username.$model"
                class="form-control"
                name="login"
                placeholder="Логин"
                type="text"
              />
              <small
                class="invalidUserData"
                v-if="v$.username.$dirty && v$.username.minLength.$invalid"
              >
                Длина логина должна быть от
                {{ v$.username.minLength.$params.min }} символов
              </small>
              <small
                class="invalidUserData"
                v-if="v$.username.$dirty && v$.username.maxLength.$invalid"
              >
                Длина логина должна быть не более
                {{ v$.username.maxLength.$params.max }} символов
              </small>
              <small
                class="invalidUserData"
                v-if="v$.username.$dirty && v$.username.isValidLogin.$invalid"
              >
                Логин может содержать только буквы латинского алфавита (a–z),
                цифры (0–9) и символы (!_$@)
              </small>
            </div>
          </div>
          <div class="item">
            <label>Имя</label>
            <div class="form-group">
              <input
                v-model="name"
                class="form-control"
                placeholder="Имя"
                type="text"
              />
            </div>
          </div>

          <div class="item">
            <label>Новый пароль</label>
            <div class="form-group">
              <input
                v-model="v$.password.$model"
                class="form-control"
                placeholder="Новый пароль"
                type="password"
              />
              <small
                class="invalidUserData"
                v-if="v$.password.$dirty && v$.password.minLength.$invalid"
              >
                Длина пароля должна быть от
                {{ v$.password.minLength.$params.min }} символов
              </small>
              <small
                class="invalidUserData"
                v-if="v$.password.$dirty && v$.password.maxLength.$invalid"
              >
                Длина пароля должна быть не более
                {{ v$.password.maxLength.$params.max }} символов
              </small>
            </div>
          </div>
          <div class="item">
            <label>Подтверждение</label>
            <div class="form-group">
              <input
                v-model.trim="v$.passwordConfirm.$model"
                class="form-control"
                placeholder="Подтверждение"
                type="password"
              />
              <small
                class="invalidUserData"
                v-if="
                  v$.passwordConfirm.$dirty &&
                    v$.passwordConfirm.sameAsPassword.$invalid
                "
              >
                Введённые пароли должны совпадать
              </small>
            </div>
          </div>
        </div>
        <div class="bottom">
          <button class="button save">Сохранить</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { maxLength, minLength, required, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axios from "axios";
import { NotificationService } from "@/services";

const passwordMask = "******";

export default {
  name: "Settings",
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    avatar_img: "/template/img/courses-mask-image.png",
    username: "",
    name: "",
    password: passwordMask,
    passwordConfirm: passwordMask
  }),
  validations() {
    return {
      username: {
        required,
        isValidLogin(login) {
          const regex = /^[a-zA-Z0-9!_$@]+$/;
          return regex.test(login);
        },
        minLength: minLength(3),
        maxLength: maxLength(32)
      },
      password: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(64)
      },
      passwordConfirm: {
        sameAsPassword: sameAs(this.password)
      }
    };
  },
  mounted() {
    let user = this.$store.getters.USER;
    this.username = user.username;
    this.name = user.name;
    this.$store.dispatch("LOAD_SUBSCRIPTION");
    this.$store.dispatch("FETCH_USER");
  },
  computed: {
    subscription() {
      return this.$store.getters.SUBSCRIPTION;
    },
    verified() {
      return this.$store.getters.USER.emailVerified;
    }
  },
  methods: {
    async sendVerifyEmail() {
      axios
        .post(process.env.VUE_APP_BACKEND_ROOT_URL + "/users/verify-email")
        .then(() => {
          NotificationService.success(
            "Запрос на верификацию успешно отправлен"
          );
        })
        .catch(() => {
          NotificationService.error("Запрос на верификацию не отправлен");
        });
    },
    async submitHandler() {
      if (this.v$.$invalid) {
        this.v$.$touch();
        return;
      }

      let formData = {
        username: this.username,
        name: this.name
      };

      if (
        passwordMask !== this.password &&
        passwordMask !== this.passwordConfirm
      ) {
        formData.password = this.password;
      }

      try {
        await this.$store.dispatch("UPDATE_SETTINGS", formData);
      } catch (e) {
        // nop
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/page-settings.scss";
</style>
